const titles = {
  people: {
    title: 'People',
    stakeholderManagement: 'Stakeholder management',
    advocates: 'Stakeholder management',
    congressionalStaffers: 'Congressional staffers',
    federalAgencyStaffers: 'Federal agency staffers',
    stateStaffers: 'State staffers',
    federalLegislators: 'Federal legislators',
    stateLegislators: 'State legislators',
    districtMap: 'District map',
    stateExecutives: 'State executives',
    localExecutives: 'Local executives',
  },
  legislationAndRegulation: {
    title: 'Legislation & regulation',
    shortTitle: 'Legs & regs',
    federalBills: 'Federal bills',
    stateBills: 'State bills',
    federalRegulations: 'Federal regulations',
    stateRegulations: 'State regulations',
    lobbyingLD2: 'Lobbying (LD-2)',
    lobbyingFARA: 'Lobbying (FARA)',
    legislatorSpreadsheetBuilder: 'Legislator spreadsheet builder',
    cosponsorsOracle: 'Cosponsors oracle',
    cosponsorshipSimilarities: 'Cosponsorship similarities',
    stateLegislatorBattingAverages: 'State legislator batting averages',
    stateSummaries: 'State summaries',
  },
  committees: {
    title: 'Committees',
    federalCommittees: 'Federal committees',
    stateCommittees: 'State committees',
    federalHearingsCalendar: 'Federal hearings calendar',
    stateHearingsCalendar: 'State hearings calendar',
  },
  documents: {
    title: 'Documents',
    federalDocuments: {
      title: 'Federal documents',
      agencyPressReleases: 'Agency press releases',
      appropriationsTracker: 'Appropriations tracker',
      cboCostEstimates: 'CBO cost estimates',
      committeeReports: 'Committee reports',
      congressionalPressReleases: 'Congressional press releases',
      congressionalRecordItems: 'Congressional record items',
      contracts: 'Contracts',
      crsReports: 'CRS reports',
      dearCollegeLetters: 'Dear Colleague letters',
      executiveOrders: 'Executive orders',
      gaoReports: 'GAO reports',
      grants: 'Grants',
      liveHearingTranscripts: 'Live hearing transcripts',
      officialHearingTranscripts: 'Official hearing transcripts',
      sapReports: 'SAP reports',
      stateOfTheUnionAddresses: 'State of the Union addresses',
      treatyDocuments: 'Treaty documents',
      thirdPartyReports: 'Third party reports',
      whiteHousePressBriefings: 'White House press briefings',
    },
    stateDocuments: {
      title: 'State documents',
      governorOrders: 'Governor orders',
      governorPressReleases: 'Governor press releases',
      globalDocumentSearch: 'Global document search',
      legacyGlobalDocumentSearch: 'Global document search (legacy)',
    },
    localDocuments: {
      title: 'Local documents',
      localDocuments: 'Local documents',
    },
  },

  donorResearch: {
    title: 'Donor research',
    pacRankings: 'PAC rankings',
    individuals: 'Individuals',
    corporations: 'Corporations',
    committees: 'Committees',
    candidates: 'Candidates',
    vendors: 'Vendors',
    receipts: 'Receipts',
    disbursements: 'Disbursements',
    smartSearch: 'Smart search',
    clusterbuckFirstQueue: 'Clusterbuck - first queue',
    clusterbuckSecondQueue: 'Clusterbuck - second queue',
  },
  advocacy: {
    title: 'Advocacy',
    grassrootsCampaigns: 'Grassroots campaigns',
    grassrootsReports: 'Grassroots reports',
    hillDay: 'Hill day',
    smsMarketing: 'SMS marketing',
    emailMarketing: 'Email marketing',
    publicTools: 'Public tools',
  },
  tracking: {
    title: 'Tracking',
    discovery: 'Discovery',
    trackingCenter: 'Tracking center',
    topics: 'Topics',
    news: 'News',
    myFeed: 'My feed',
    workspaces: 'Workspaces',
  },
  grm: {
    title: 'Activity',
  },
  globalSearch: {
    title: 'Global search',
  },
  accountSettings: {
    title: 'Account settings',
    manageProfile: 'Manage profile',
    changePassword: 'Change password',
    manageProfilePhoto: 'Manage profile photo',
    admin: 'Admin',
    logOut: 'Log out',
  },
  organizationSettings: {
    title: 'Organization settings',
    manageOrganization: 'Manage organization',
    users: 'Manage users',
    sso: 'Single Sign-On (SSO)',
    inviteUsers: 'Invite users',
    institutionalData: 'Institutional data',
  },
  help: {
    title: 'Help',
    generalHelp: 'General help',
    liveSupport: 'Live support',
  },
  myProfile: {
    title: 'My profile',
    manageProfile: 'Manage profile',
    changePassword: 'Change password',
    manageProfilePhoto: 'Manage profile photo',
  },
};

export default titles;
