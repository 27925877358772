import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TippyTooltip from '@tippyjs/react';

import withNavigation from 'components/Navigation/utils/withNavigation';
import bannerMessages from 'components/Navigation/menuData/bannerMessage';
import Link from 'components/core/Link';
import Banner from 'components/core/Banner';

import { trackTopLevelItemClicked } from '../../utils/tracking';
import { menuItemShape } from '../TooltipMenu/utils/shapes';
import TooltipMenu from '../TooltipMenu';
import { checkActiveItem, getItemTitle } from './utils';
import useBarItem from './useBarItem';
import styles from './styles.module.scss';

function NavigationBarItem({
  id,
  title,
  shortTitle,
  href,
  subItems,
  withNestedLevel,
  isDesktopLarge,
  columnsCount,
  mediumScreenColumnsCount,
  onItemClick,
  disabledProducts,
}) {
  const isActiveItem = checkActiveItem({ href, subItems });

  const navigationBarItemClassNames = classNames(
    isActiveItem && styles.activeItem,
    styles.navigationBarItem
  );

  const itemTitle = getItemTitle({ title, shortTitle, isDesktopLarge });

  const handleItemClicked = () => onItemClick({ itemTitle });

  const itemProps = {
    'data-title': itemTitle,
  };

  const itemMenuColumnsCount = (mediumScreenColumnsCount && !isDesktopLarge)
    ? mediumScreenColumnsCount
    : columnsCount;

  const {
    showLinkOnly,
    showBanner,
  } = useBarItem({ subItems, id, disabledProducts });

  if (showLinkOnly) {
    return (
      <div className={navigationBarItemClassNames} {...itemProps}>
        <Link
          theme="default"
          href={href}
          className={styles.pageLink}
          onClick={handleItemClicked}
        >
          {itemTitle}
        </Link>
      </div>
    );
  }

  const tooltipMenu = () => (
    <TooltipMenu
      items={subItems}
      withNestedLevel={withNestedLevel}
      columnsCount={itemMenuColumnsCount}
    />
  );

  const tooltipBanner = () => (
    <Banner
      message={bannerMessages[id]}
      className={styles.banner}
    />
  );

  const tooltipContent = showBanner
    ? tooltipBanner()
    : tooltipMenu();

  return (
    <TippyTooltip
      content={tooltipContent}
      className={styles.menuTooltip}
      placement="bottom"
      trigger="click"
      maxWidth="none"
      theme="light"
      interactive
      arrow
      appendTo={document.body}
      onShow={handleItemClicked}
    >
      <span {...itemProps} className={navigationBarItemClassNames}>
        {itemTitle}
      </span>
    </TippyTooltip>
  );
}

NavigationBarItem.defaultProps = {
  onItemClick: trackTopLevelItemClicked,
};

NavigationBarItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  shortTitle: PropTypes.string,
  href: PropTypes.string,
  subItems: PropTypes.arrayOf(menuItemShape),
  isDesktopLarge: PropTypes.bool.isRequired,
  withNestedLevel: PropTypes.bool,
  columnsCount: PropTypes.number,
  mediumScreenColumnsCount: PropTypes.number,
  onItemClick: PropTypes.func,
  disabledProducts: PropTypes.arrayOf(PropTypes.string),
};

export default withNavigation(NavigationBarItem);
