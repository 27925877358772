import React from 'react';
import PropTypes from 'prop-types';

import Highlighter from 'components/core/Highlighter';
import NotFoundResults from 'components/Navigation/common/NotFoundResults';

import styles from './styles.module.scss';

function List({ items, highlightValue, isSearching }) {
  const NotFoundText = (
    <div className={styles.searchContainer}>
      <img className={styles.searchImg} src="/images/search-icon.svg" alt="Search icon" />
      <p className={styles.searchTitle}>Looking for something ?</p>
      <p>Use the search bar above to find federal bills, committees, and legislators.</p>
    </div>
  );

  const Items = items.map(item => (
    <li key={item.value} className={styles.item}>
      <a href={item.link}>
        <Highlighter
          className={styles.linkText}
          searchValue={highlightValue}
          content={item.label}
        />
        <span className={styles.itemType}>
          {item.type}
        </span>
      </a>
    </li>
  ));

  const ListItems = (
    <ul className={styles.navList}>
      {Items}
    </ul>
  );

  return isSearching ? ListItems : <NotFoundResults>{NotFoundText}</NotFoundResults>;
}

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  highlightValue: PropTypes.string,
  isSearching: PropTypes.bool,
};

export default List;
