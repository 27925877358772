import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import useMenu from 'components/Navigation/utils/useMenu';
import { USER_MENU } from 'components/Navigation/utils';
import Button from 'components/core/Button';
import MenuItemsList from 'components/Navigation/MobileView/common/MenuItemsList';
import FooterBar from 'components/Navigation/MobileView/common/FooterBar';
import FooterBarV2 from 'components/NavigationV2/components/MobileView/components/common/FooterBar';
import MenuTransition from 'components/Navigation/common/MenuTransition';
import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import { findUserMenuEnabledItems, getUserMenuById } from './utils';
import styles from './styles.module.scss';

function UserProfileMenu({ isUserMenuOpen }) {
  const { isMobile } = useMediaQuery();
  const transitionMenuId = isUserMenuOpen ? 'openId' : 'closedId';
  const enabledItems = findUserMenuEnabledItems(isMobile);
  const {
    menuItems,
    isBackItem,
    isBackHome,
    handleNavigateHome,
    handleNavigateItem,
    handleNavigateParent,
  } = useMenu({
    items: enabledItems,
    filterFunction: getUserMenuById,
    navId: USER_MENU,
  });

  if (isEmpty(menuItems)) {
    return null;
  }

  const userMenuClassNames = classNames(
    styles.userMenu,
    isUserMenuOpen && styles.userMenuActive,
  );

  const FooterBarComponent = gon.isNavigationV2Enabled ? FooterBarV2 : FooterBar;

  function renderFooterBar() {
    if (!isUserMenuOpen) {
      return null;
    }

    return (
      <FooterBarComponent className={styles.footerBar}>
        {isBackHome && (
          <>
            <Button onClick={handleNavigateParent} className={styles.iconButton} theme="light">
              <i className="fas fa-arrow-left" />
            </Button>
            <Button onClick={handleNavigateHome} className={styles.iconButton} theme="light">
              <i className="fas fa-home" />
            </Button>
          </>
        )}
      </FooterBarComponent>
    );
  }

  return (
    <div className={styles.container}>
      <MenuTransition id={transitionMenuId}>
        <section id="userMenu" className={userMenuClassNames}>
          <MenuItemsList
            menuItems={menuItems}
            isBackItem={isBackItem}
            handleNavigateNext={handleNavigateItem}
            parentTitle="Account"
          />
        </section>
      </MenuTransition>

      {renderFooterBar()}
    </div>
  );
}

UserProfileMenu.propTypes = {
  isUserMenuOpen: PropTypes.bool.isRequired,
};

export default UserProfileMenu;
