import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { menuItemShape } from './utils/shapes';
import MenuItem from './components/MenuItem';
import styles from './styles.module.scss';

function TooltipMenu({
  items,
  withNestedLevel,
  columnsCount,
  className,
}) {
  const renderItem = (item, index) => (
    <MenuItem
      {...item}
      withNestedLevel={withNestedLevel}
      key={`${item.title}${index}`}
    />
  );

  const columnsStyle = {
    columns: columnsCount,
  };

  return (
    <div
      className={classNames(
        styles.menu,
        withNestedLevel && styles.withNestedLevel,
        className,
      )}
      style={columnsStyle}
    >
      {items.map(renderItem)}
    </div>
  );
}

TooltipMenu.propTypes = {
  items: PropTypes.arrayOf(menuItemShape).isRequired,
  withNestedLevel: PropTypes.bool,
  columnsCount: PropTypes.number,
  className: PropTypes.string,
};

export default TooltipMenu;
