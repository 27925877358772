import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import MenuItem from '../../TooltipMenu/components/MenuItem';
import { menuItemShape } from '../../TooltipMenu/utils/shapes';
import styles from '../styles.module.scss';

function MenuContent({ items }) {
  const [firstColumn, secondColumn] = items;

  const renderItem = (item, index) => (
    <MenuItem
      {...item}
      key={`${item.title}${index}`}
      parentClassName={styles.menuItem}
      leafClassName={styles.leafNode}
    />
  );

  return (
    <div className={styles.menu}>
      <div className={styles.firstColumn}>{map(firstColumn, renderItem)}</div>
      {map(secondColumn, renderItem)}
    </div>
  );
}

MenuContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.arrayOf(menuItemShape)).isRequired,
};

export default MenuContent;
