import isEmpty from 'lodash/isEmpty';

function useBarItem({ subItems, id, disabledProducts }) {
  const showBanner = disabledProducts.includes(id);
  const showMenuItems = !showBanner && !isEmpty(subItems);
  const showLinkOnly = !showBanner && !showMenuItems;

  return {
    showBanner,
    showLinkOnly,
    showMenuItems,
  };
}

export default useBarItem;
