import PropTypes from 'prop-types';

const menuLeafNodeShape = {
  title: PropTypes.string,
  href: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  betaFlag: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
};

const menuNodeShape = {
  title: PropTypes.string,
};

menuNodeShape.subItems = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.shape(menuLeafNodeShape),
    PropTypes.shape(menuNodeShape),
  ])
);

export const menuItemShape = PropTypes.oneOfType([
  PropTypes.shape(menuLeafNodeShape),
  PropTypes.shape(menuNodeShape),
]);
