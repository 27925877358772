export const SEARCH_MENU = 'search';
export const APP_MENU = 'menu';
export const USER_MENU = 'user';
export const NEW_NOTE = 'newNote';

export const COMPANY_ADMIN = 'company_admin';
export const GOVPREDICT_ADMIN = 'govpredict_admin';
export const PERMIT_MONEYBALL_CLUSTERBUCK = 'permitMoneyballClusterbuck';

function getUserRole() {
  const {
    companyAdmin,
    govpredictAdmin,
    isMoneyballEnabled,
    permitMoneyballClusterbuck,
  } = gon.currentUser;

  return [
    companyAdmin && COMPANY_ADMIN,
    govpredictAdmin && GOVPREDICT_ADMIN,
    permitMoneyballClusterbuck && PERMIT_MONEYBALL_CLUSTERBUCK,
    isMoneyballEnabled,
  ];
}

export function isGovPredictUser() {
  const { companyId, env } = gon || {};

  return (env === 'production' && String(companyId) === '153')
      || (env === 'development' && String(companyId) === '1')
      || (env === 'staging' && String(companyId) === '1');
}

export function authorizeItemByRole(item) {
  const { roles } = item;
  const userRole = getUserRole();

  if (roles && roles.length > 0) {
    return roles.some(role => userRole.includes(role));
  }

  return item;
}

export const authorizeItemByRoleAndBetaFlag = (item, roleAuthorizer = authorizeItemByRole) => {
  const { roles, betaFlag } = item;

  const userHasCorrectRole = roleAuthorizer({ roles });
  const userHasBetaAccess = !betaFlag || gon[betaFlag];

  return userHasCorrectRole && userHasBetaAccess;
};

export function filterItemByUserRole(items) {
  return items.filter(authorizeItemByRole);
}

export function filterItemByUserDevice(menuItem, isMobile) {
  const { id, items } = menuItem;

  if (isMobile && id === 'parent') {
    return items.filter(item => item.title !== 'Admin');
  }

  return items;
}

export function getLinkBreadcrumb(title, href) {
  return `<a href="${href}">${title}</a>`;
}
