import React from 'react';

import applicationMenuItems from 'components/Navigation/menuData/applicationMenuItems';

import NavigationBarItem from './NavigationBarItem';
import styles from './styles.module.scss';

function NavigationBar() {
  const renderItem = (item, index) => (
    <NavigationBarItem
      {...item}
      key={`${item.title}${index}`}
    />
  );

  return (
    <div className={styles.navigationBar}>
      {applicationMenuItems.map(renderItem)}
    </div>
  );
}

export default NavigationBar;
