import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import NotFoundResults from 'components/Navigation/common/NotFoundResults';
import withMenuItems from 'components/Navigation/MobileView/utils/withMenuItems';
import MenuItemsList from 'components/Navigation/MobileView/common/MenuItemsList';
import MenuTitle from 'components/Navigation/MobileView/common/MenuTitle';
import MenuItem from 'components/Navigation/MobileView/common/MenuItem';
import DrillDown from 'components/Navigation/common/DrillDown';

import styles from './styles.module.scss';

function SearchMenu({
  searchValue,
  menuItems,
  isInitItemStep,
  getFoundItemsTitle,
  getParentTitle,
  isBackItem,
  handleNavigateNext,
  disabledProducts,
}) {
  if (isEmpty(menuItems)) {
    return (
      <div className={styles.container}>
        <NotFoundResults>
          No results found for <b>{searchValue}</b>
        </NotFoundResults>
      </div>
    );
  }

  /* User is navigating in a submenu of the listed menus on search */
  if (!isInitItemStep) {
    return (
      <MenuItemsList
        isBackItem={isBackItem}
        menuItems={menuItems}
        handleNavigateNext={handleNavigateNext}
        disabledProducts={disabledProducts}
      />
    );
  }

  function renderChildItem(item, index) {
    return (
      <MenuItem
        key={`${item.id}-${index}`}
        item={item}
        searchValue={searchValue}
        handleNavigateNext={handleNavigateNext}
        className={styles.menuItem}
        textClassName={styles.menuItemText}
        iconClassName={styles.icon}
        disabledProducts={disabledProducts}
      />
    );
  }

  function renderMenuItem(menuItem, index) {
    const { items = [], title } = menuItem;
    const parentTitle = getParentTitle(title);
    const isFirst = index === 0;

    return (
      <Fragment key={`menuItem-${index}`}>
        {parentTitle && (
          <div className={classNames(styles.parentTitle, !isFirst && styles.parentOffset)}>
            {parentTitle}
          </div>
        )}
        {items.map(renderChildItem)}
      </Fragment>
    );
  }

  const { id } = menuItems[0] || {};

  return (
    <DrillDown isFullHeight id={id} isBackDirection={isBackItem}>
      <div className={styles.container}>
        <MenuTitle title={getFoundItemsTitle()} />
        {menuItems.map(renderMenuItem)}
      </div>
    </DrillDown>
  );
}

SearchMenu.propTypes = {
  isInitItemStep: PropTypes.bool.isRequired,
  searchValue: PropTypes.string.isRequired,
  getFoundItemsTitle: PropTypes.func.isRequired,
  getParentTitle: PropTypes.func.isRequired,
  handleNavigateNext: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          goTo: PropTypes.string,
          groupTitle: PropTypes.string,
          groupValue: PropTypes.string,
          slug: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
    }),
  ),
  isBackItem: PropTypes.bool.isRequired,
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default withMenuItems(SearchMenu);
