import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './styles.module.scss';

function MenuTransition({ children, id, transitionDuration = 300, isSearchMenu, enable = true }) {
  const transitionClassNames = !isSearchMenu
    ? {
      enterActive: styles.transitionEnteringBack,
      exitActive: styles.transitionExiting,
    }
    : {
      enterActive: styles.searchTransitionEnteringBack,
      exitActive: styles.searchTransitionExiting,
    };

  const noTransitionClassNames = {
    ...transitionClassNames,
    enterActive: '',
  };

  return (
    <TransitionGroup className={styles.transitionContainer} component={null} appear={enable}>
      <CSSTransition
        key={id}
        timeout={transitionDuration}
        classNames={enable ? transitionClassNames : noTransitionClassNames}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
}

MenuTransition.propTypes = {
  id: PropTypes.string,
  transitionDuration: PropTypes.number,
  isSearchMenu: PropTypes.bool,
  enable: PropTypes.bool,
  children: PropTypes.node,
};

export default MenuTransition;
