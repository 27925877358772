import React from 'react';
import styles from './styles.module.scss';

const NavigationWarningBanner = () => (
  // eslint-disable-next-line react/jsx-filename-extension
  <div className={styles.warningBannerStyle}>
    <p className={styles.warningBannerTextStyle}>
      Your subscription to Intelligence (aka GovPredict) has either ended or been migrated to Quorum.
      Please contact our support team through the Chat feature on this site or by email at
      <a href="mailto:p2asupport@quorum.us"> p2asupport@quorum.us</a>
    </p>
  </div>
);

export default NavigationWarningBanner;
