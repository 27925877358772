import { useEffect, useState } from 'react';
import startCase from 'lodash/startCase';
import slugs from 'components/Navigation/menuData/slugs';

import { findMenuBySearchValue } from 'components/Navigation/MobileView/utils';
import { APP_MENU } from 'components/Navigation/utils';
import { resetNavStateToParent } from 'components/Navigation/utils/navStateItems';

/** List items = Format title from id - transform snake case to sentence case */
function getParentTitle(title) {
  const titles = (title && title.split('-').filter((text) => text !== 'parent')) || [];

  return titles.map((t) => startCase(t)).join(' / ') || null;
}

function useSearchMenuSteps(
  searchValue,
  setSearchValue,
  enabledItems,
  menuProps,
) {
  /** For tracking next/back steps */
  const itemStepsInitial = [{ step: 0, id: searchValue }];
  const [itemSteps, setItemSteps] = useState(itemStepsInitial);
  const [initStep, setInitStep] = useState(true);
  const itemStepsLength = itemSteps.length - 1;
  const isInitItemStep = itemStepsLength === 0;

  const {
    menuItems,
    setMenuItems,
    activeMenuId,
    setActiveMenuId,
    isBackItem,
    setIsBackItem,
    handleNavigateParent,
    setShowReturnArrow,
  } = menuProps;

  function setSearchMenuItems() {
    const foundMenuItems = findMenuBySearchValue(searchValue, enabledItems);

    setActiveMenuId(null);
    setMenuItems(foundMenuItems);
    resetNavStateToParent(APP_MENU);
  }

  function resetMenuStateParent() {
    setActiveMenuId(slugs.parentMenu);
  }

  useEffect(() => {
    if (searchValue) {
      isInitItemStep && setSearchMenuItems();
      setInitStep(isInitItemStep);
    }
  }, [isInitItemStep]);

  useEffect(() => {
    if (searchValue) {
      setShowReturnArrow(true);
      setSearchMenuItems();
      setItemSteps(itemStepsInitial);
    } else {
      const [firstItem] = itemSteps;
      // previous search value, reset only if user was searching
      if (firstItem.id) {
        resetMenuStateParent();
      }

      setShowReturnArrow(false);
    }
  }, [searchValue]);

  function goToNextMenu(menuId) {
    const nextStep = { step: (itemStepsLength) + 1, id: menuId };

    setActiveMenuId(menuId);
    setItemSteps((prevState) => ([...prevState, nextStep]));
    setIsBackItem(false);
  }

  function goToBackMenu() {
    if (isInitItemStep) {
      /** Reset Menu - All parent items */
      setSearchValue('');
      resetMenuStateParent();
    } else {
      setItemSteps((prevState) => prevState.slice(0, -1));
      handleNavigateParent();
    }

    setIsBackItem(true);
  }

  /** Display found items title */
  function getFoundItemsTitle() {
    if (!initStep) {
      return menuItems[0].title;
    }

    let foundItemsLength = 0;
    menuItems.forEach((menu) => { foundItemsLength += menu.items.length; });

    return `${foundItemsLength} results`;
  }

  return {
    isInitItemStep,
    activeMenuId,
    menuItems,
    isBackItem,
    setIsBackItem,
    goToNextMenu,
    goToBackMenu,
    getParentTitle,
    getFoundItemsTitle,
  };
}

export default useSearchMenuSteps;
