import { useState, useEffect } from 'react';
import useDebounce from 'components/DelayedInput/useDebounce';

function useSearchInput() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const searchDebounceInput = useDebounce(searchInput);

  useEffect(() => {
    const isTheSameInput = searchInput === searchDebounceInput;
    isTheSameInput && setIsLoading(false);
  }, [searchDebounceInput, searchInput]);

  function onChangeValue(value) {
    setSearchInput(value);
    setIsLoading(true);
  }

  return {
    isLoading,
    setIsLoading,
    searchInput,
    searchDebounceInput,
    setSearchInput,
    onChangeValue,
  };
}

export default useSearchInput;
