import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function NotFoundResults({ children }) {
  return (
    <div className={styles.noFoundSection}>
      <div className={styles.noFoundText}>
        {children}
      </div>
    </div>
  );
}

export default NotFoundResults;

NotFoundResults.propTypes = {
  children: PropTypes.node,
};
