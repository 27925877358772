import PropTypes from 'prop-types';

export const menuItemShape = PropTypes.shape({
  title: PropTypes.string,
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  linkProperties: PropTypes.shape({
    target: PropTypes.string,
    'data-method': PropTypes.string,
    rel: PropTypes.string,
  }),
  goTo: PropTypes.string,
  icon: PropTypes.string,
  isLocked: PropTypes.bool,
});
