import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import { authorizeItemByRoleAndBetaFlag } from 'components/Navigation/utils';
import slugs from 'components/Navigation/menuData/slugs';
import DrillDown from 'components/Navigation/common/DrillDown';
import Loader from 'components/core/Loader';
import { isIOS } from 'utils/device';

import { menuItemShape } from '../utils/shapes';
import MenuItem from '../MenuItem';
import MenuTitle from '../MenuTitle';
import styles from './styles.module.scss';

function MenuItemsList({
  menuItems,
  isBackItem,
  handleNavigateNext,
  parentTitle,
  disabledProducts,
  isLoading,
  startLoading,
}) {
  const itemsContainerStyles = classNames(
    styles.itemsContainer,
    isIOS && styles.itemsContainerIphone,
  );

  if (isEmpty(menuItems)) {
    return null;
  }

  const { items, title, id } = menuItems[0];

  function getTitle() {
    if (title === slugs.parentMenu || isEmpty(title)) {
      return parentTitle;
    }

    return title;
  }

  function renderItem(menuItem, index) {
    const { slug } = menuItem;

    if (!authorizeItemByRoleAndBetaFlag(menuItem)) {
      return null;
    }

    return (
      <MenuItem
        key={`${slug}-${index}`}
        item={menuItem}
        handleNavigateNext={handleNavigateNext}
        className={styles.menuItemContainer}
        disabledProducts={disabledProducts}
        onLinkClick={startLoading}
      />
    );
  }

  return (
    <DrillDown isFullHeight id={id} isBackDirection={isBackItem}>
      <div className={styles.container}>
        <MenuTitle title={getTitle()} />
        <div className={itemsContainerStyles}>
          {items.map(renderItem)}
        </div>

        {isLoading && (
          <div className={styles.loadingOverlay}>
            <Loader onlyIcon />
          </div>
        )}
      </div>
    </DrillDown>
  );
}

MenuItemsList.defaultProps = {
  parentTitle: 'Directory',
  isLoading: false,
  startLoading: () => {},
};

MenuItemsList.propTypes = {
  menuItems: PropTypes.arrayOf(menuItemShape),
  handleNavigateNext: PropTypes.func,
  isBackItem: PropTypes.bool.isRequired,
  parentTitle: PropTypes.string,
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ),
  isLoading: PropTypes.bool,
  startLoading: PropTypes.func,
};

export default MenuItemsList;
