import React from 'react';
import PropTypes from 'prop-types';
import TippyTooltip from '@tippyjs/react';

import withNavigation from 'components/Navigation/utils/withNavigation';
import userMenuItems from 'components/Navigation/menuData/userMenuItems';
import Avatar from 'components/core/Avatar';

import { NAVIGATION_USER_PROFILE_IMG_ID } from '../../../utils/constants';
import { trackUserMenuOpened } from '../../utils/tracking';
import MenuContent from './MenuContent';
import styles from './styles.module.scss';

export function UserMenuButtonComponent({
  userImgPath,
  userName,
  onMenuOpen,
}) {
  const userMenu = <MenuContent items={userMenuItems} />;

  return (
    <TippyTooltip
      content={userMenu}
      className={styles.menuTooltip}
      placement="bottom"
      trigger="click"
      maxWidth="none"
      theme="light"
      interactive
      arrow
      appendTo={document.body}
      onShow={onMenuOpen}
    >
      <span className={styles.tooltipTrigger}>
        <Avatar
          id={NAVIGATION_USER_PROFILE_IMG_ID}
          className={styles.avatar}
          photoUrl={userImgPath}
          name={userName}
        />
      </span>
    </TippyTooltip>
  );
}

UserMenuButtonComponent.defaultProps = {
  onMenuOpen: trackUserMenuOpened,
};

UserMenuButtonComponent.propTypes = {
  userImgPath: PropTypes.string,
  userName: PropTypes.string.isRequired,
  onMenuOpen: PropTypes.func,
};

export default withNavigation(UserMenuButtonComponent);
