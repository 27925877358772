import { getLinkBreadcrumb } from 'components/Navigation/utils';

import titles from './titles';

const breadcrumbs = {
  hillDay: getLinkBreadcrumb(
    titles.advocacy.hillDay,
    '/hill_day/hill_days',
  ),
  stakeholderManagement: getLinkBreadcrumb(
    titles.people.stakeholderManagement,
    '/stakeholder_management/directory',
  ),
  advocates: getLinkBreadcrumb(
    titles.people.advocates,
    '/advocates',
  ),
  grm: getLinkBreadcrumb(
    titles.grm.title,
    '/grm',
  ),
  congressionalStaffers: getLinkBreadcrumb(
    titles.people.congressionalStaffers,
    '/federal/congressional_staffers',
  ),
  federalLegislators: getLinkBreadcrumb(
    titles.people.federalLegislators,
    '/federal/legislators',
  ),
  federalBills: getLinkBreadcrumb(
    titles.legislationAndRegulation.federalBills,
    '/federal/bills',
  ),
  stateBills: getLinkBreadcrumb(
    titles.legislationAndRegulation.stateBills,
    '/state/bills',
  ),
  stateLegislators: getLinkBreadcrumb(
    titles.people.stateLegislators,
    '/state/legislators',
  ),
  federalCommittees: getLinkBreadcrumb(
    titles.committees.federalCommittees,
    '/federal/committees',
  ),
  stateCommittees: getLinkBreadcrumb(
    titles.committees.stateCommittees,
    '/state/committees',
  ),
  stateStaffers: getLinkBreadcrumb(
    titles.people.stateStaffers,
    '/state/staffers',
  ),
  stateRegulations: getLinkBreadcrumb(
    titles.legislationAndRegulation.stateRegulations,
    '/state/regulations',
  ),
  individuals: getLinkBreadcrumb(
    titles.donorResearch.individuals,
    '/moneyball_v2/individuals/lookup',
  ),
  corporations: getLinkBreadcrumb(
    titles.donorResearch.corporations,
    '/moneyball_v2/corporations/lookup',
  ),
  committees: getLinkBreadcrumb(
    titles.donorResearch.committees,
    '/moneyball_v2/committees/lookup',
  ),
  candidates: getLinkBreadcrumb(
    titles.donorResearch.candidates,
    '/moneyball_v2/candidates/lookup',
  ),
  vendors: getLinkBreadcrumb(
    titles.donorResearch.vendors,
    '/moneyball_v2/vendors/lookup',
  ),
};

export default breadcrumbs;
