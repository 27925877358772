import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './styles.module.scss';

export const TRANSITION_DURATION = 220;

function Transition({
  children,
  id,
  transitionDuration = TRANSITION_DURATION,
  reverseTransitionDirection,
  isFullHeight,
  className,
}) {
  const containerClassName = classNames(
    styles.transitionContainer,
    isFullHeight && styles.fullHeight,
    className,
  );

  return (
    <TransitionGroup className={containerClassName}>
      <CSSTransition
        key={id}
        timeout={transitionDuration}
        classNames={{
          enterActive: reverseTransitionDirection ? styles.transitionEnteringBack : styles.transitionEntering,
          exitActive: reverseTransitionDirection ? styles.transitionExitingBack : styles.transitionExiting,
        }}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
}

Transition.propTypes = {
  id: PropTypes.string,
  transitionDuration: PropTypes.number,
  reverseTransitionDirection: PropTypes.bool,
  children: PropTypes.node,
  isFullHeight: PropTypes.bool,
  className: PropTypes.string,
};

export default Transition;
