const slugs = {
  grassroots: 'grassroots',
  federal: 'federal',
  advocacy: 'advocacy',
  grm: 'grm',
  hillDay: 'hill_day',
  lobbyingResearch: 'lobbying_research',
  publicFacingTools: 'public_facing_tools',
  statePublicFacingTools: 'state_public_facing_tools',
  federalPublicFacingTools: 'federal_public_facing_tools',
  dashboard: 'dashboard',
  dashboardV2: 'dashboard_v2',
  local: 'local',
  moneyball: 'moneyball',
  moneyballV2: 'moneyball_v2',
  state: 'state',
  globalSearch: 'global_search',
  emailBlasts: 'email_blasts',
  smsMarketing: 'sms_marketing',
  parentMenu: 'parent',
  stakeholderManagement: 'stakeholder_management',
  advocates: 'stakeholder_management',
  federalStaffers: 'federal_staffers',
  stateRegulation: 'state_regulation',
  news: 'news',
  people: 'people',
  legislationAndRegulation: 'legislation_and_regulation',
  committees: 'committess',
  documents: 'documents',
  federalDocuments: 'federal_documents',
  stateDocuments: 'state_documents',
  localDocuments: 'local_documents',
  donorResearch: 'donor_research',
  tracking: 'tracking',
  trackingCenter: 'tracking_center',
  cosponsorshipSimilarities: 'cosponsorship_similarities',
  districtMap: 'district_map',
  myProfile: 'my_profile',
  organizationSettings: 'organization_settings',
  help: 'help',
  customFields: 'custom_fields',
  localExecutives: 'local_executives',
};

export const childSlugs = {
  [slugs.dashboard]: [slugs.federalStaffers],
  [slugs.federal]: [slugs.federalStaffers],
};

export default slugs;
