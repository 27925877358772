import { useState } from 'react';
import { filterGlobalFunction } from 'components/Navigation/QuickSearch/utils';

function useQuickSearch() {
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  function onChangeValue(value) {
    setIsSearching(false);
    setSearchValue(value);
  }

  function onClickSearch() {
    setIsLoading(true);
    setIsSearching(true);

    filterGlobalFunction(searchValue, setIsLoading, setItems);
  }

  function onClearValue() {
    setSearchValue('');
    setIsSearching(false);
  }

  return {
    items,
    searchValue,
    isLoading,
    isSearching,
    setIsSearching,
    onChangeValue,
    onClickSearch,
    onClearValue,
  };
}

export default useQuickSearch;
