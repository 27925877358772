import React from 'react';
import PropTypes from 'prop-types';

import Transition from './Transition';
import styles from './styles.module.scss';

function DrillDown({ id, isBackDirection, children, isFullHeight, className }) {
  return (
    <Transition
      id={id}
      isFullHeight={isFullHeight}
      reverseTransitionDirection={isBackDirection}
      className={className}
    >
      <nav>
        <ul className={styles.list}>
          {children}
        </ul>
      </nav>
    </Transition>
  );
}

DrillDown.propTypes = {
  id: PropTypes.string,
  isBackDirection: PropTypes.bool,
  children: PropTypes.node,
  isFullHeight: PropTypes.bool,
  className: PropTypes.string,
};

export default DrillDown;
