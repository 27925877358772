import { isEmpty, flow, map, groupBy, merge } from 'lodash';

import appMenuData from 'components/Navigation/menuData/mobileNavigationData';
import userMenuData from 'components/Navigation/menuData/mobileUserMenuItems';
import { childSlugs } from 'components/Navigation/menuData/slugs';
import { filterItemByUserRole } from 'components/Navigation/utils';
import { ROOT_BREADCRUMB } from 'components/Navigation/utils/constants';
import { fuzzyMatch } from 'utils/search';

/* Replace page link with lock item link  */
function setLockedItem(item) {
  /** Replace Icon with Lock for locked item */
  item.icon = 'fas fa-lock-alt';
  item.isLocked = true;
  item.goTo = '';
  item.href = item.slugLink || item.href || `/${item.slug}`;
}

/**
 * Get all locked Items from Backend and remove them from menu data
 * */
export function removeLockedItemsForMenu(lockedItems, menuData = appMenuData) {
  let filteredItems = merge([], menuData);

  filteredItems = filterItemByUserRole(filteredItems);
  const menuWithSubproducts = [];

  filteredItems.forEach((menuItem) => {
    /** Removed items that is not enabled for current user role */
    menuItem.items = filterItemByUserRole(menuItem.items);

    /**
     * Remove items from menu that are locked.
     * If the item is locked but the menu has a subproduct inside
     * that is not locked, the item is not removed.
     */
    const itemChildSlugs = childSlugs[menuItem.slug];
    const menuSubproducts = itemChildSlugs && itemChildSlugs.filter((slug) => !lockedItems.includes(slug));
    if (lockedItems.includes(menuItem.slug)) {
      if (menuSubproducts && menuSubproducts.length) {
        menuWithSubproducts.push(menuItem.slug);
      } else {
        filteredItems = filteredItems.filter((initItem) => initItem.slug !== menuItem.slug);
      }
    }
  });

  filteredItems.forEach((menuItem) => {
    /** Set as locked if the item does not contain an enabled subproduct */
    menuItem.items.forEach((item) => {
      if (lockedItems.includes(item.slug) && !menuWithSubproducts.includes(item.goTo)) {
        setLockedItem(item);
      }
    });
  });

  return filteredItems;
}

/**
 * Find menu when click next + init
 * */
export function findMenuItemsById(activeId, enabledItems) {
  if (!isEmpty(activeId)) {
    return enabledItems.filter((item) => item.id === activeId);
  }

  return enabledItems;
}

/**
 * Find menu when user is typing in search application
 * */
export function findMenuBySearchValue(searchValue, enabledProducts) {
  const searchedItems = [];

  enabledProducts.forEach((dataItem) => {
    /** Iterate over nested items from menu data */
    dataItem.items.forEach((item) => {
      const isPresentSearchValueInTitle = fuzzyMatch(searchValue, item.title);

      if (isPresentSearchValueInTitle) {
        searchedItems.push({ groupValue: dataItem.id || '', ...item, groupTitle: dataItem.searchParentTitle });
      }
    });
  });

  /** For displaying data title + items (present only when is search by application) */
  const groupItemsByTitle = (allItems) => groupBy(allItems, (i) => i.groupValue);
  const transformFoundItems = (allItems) => map(
    allItems,
    (value, title) => ({ title, items: value }),
  );

  return flow([
    groupItemsByTitle,
    transformFoundItems,
  ])(searchedItems);
}

function filterIsLinkPresent(items, link) {
  return items && items.filter((item) => link && link.includes(item.href)).length > 0;
}

function findInsidePage(items, link) {
  return items && items.find((item) => {
    const linkPage = item.pages && item.pages
      .find((page) => page.linkMatcher.test(link));

    return linkPage;
  });
}

export function findBreadcrumbs(link) {
  const foundItems = [];
  let insidePage = {};
  const fallbackBreadcrumb = { breadcrumb: ROOT_BREADCRUMB };

  [...appMenuData, ...userMenuData].forEach((data) => {
    const isFoundLink = filterIsLinkPresent(data.items, link);
    const isFoundHideItemsLink = filterIsLinkPresent(data.hideItems, link);
    const insidePageFound = findInsidePage(data.items, link);

    if (isFoundLink || isFoundHideItemsLink) {
      foundItems.push(data);
    }

    if (insidePageFound) {
      insidePage = insidePageFound;
    }
  });
  if (insidePage.breadcrumb) {
    return insidePage;
  }

  if (foundItems.length > 0 && foundItems[0].breadcrumb) {
    return foundItems[0];
  }

  return fallbackBreadcrumb;
}
