import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from 'components/core/Input';
import Button from 'components/core/Button';
import Loader from 'components/core/Loader';
import withMenuItems from 'components/Navigation/MobileView/utils/withMenuItems';
import MenuItemsList from 'components/Navigation/MobileView/common/MenuItemsList';
import HelpButton from 'components/core/HelpButton';
import Notifications from 'components/core/Notifications';
import { NAVIGATION_NOTIFICATIONS_DOM_ID } from 'components/core/Notifications/utils/constants';

import { menuItemShape } from '../../common/utils/shapes';
import SearchMenuList from '../SearchMenuList';
import styles from './styles.module.scss';

function ActiveMenu({
  menuItems,
  isBackItem,
  searchValue,
  isSearching,
  isLoadingSearch,
  onChangeSearch,
  onClearSearch,
  handleNavigateNext,
  isMobilePage,
  disabledProducts,
  isLoading,
  startLoading,
  stopLoading,
}) {
  const { isNotificationEnabled = false } = gon || {}; // TODO: adjust once notifications is out of beta
  const appMenuId = 'appMenu';
  const parentSelector = () => document.getElementById(appMenuId);

  function handleChange(event) {
    const { value } = event.target;

    onChangeSearch(value);
  }

  function renderContent() {
    if (isSearching) {
      return isLoadingSearch
        ? <Loader />
        : <SearchMenuList />;
    }

    return (
      <MenuItemsList
        menuItems={menuItems}
        isBackItem={isBackItem}
        handleNavigateNext={handleNavigateNext}
        disabledProducts={disabledProducts}
        isLoading={isLoading}
        startLoading={startLoading}
        stopLoading={stopLoading}
      />
    );
  }

  const onPageShow = useCallback((event) => {
    if (event.persisted) {
      stopLoading();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('pageshow', onPageShow);

    return () => window.removeEventListener('pageshow', onPageShow);
  }, []);

  return (
    <div id={appMenuId} className={styles.container}>
      <header className={styles.searchHeader}>
        <a href="/">
          <img className={styles.logo} src="/images/intelligence-icon-dark.png" alt="Intelligence logo" />
        </a>
        <div className={styles.inputContainer}>
          <Input
            value={searchValue}
            onChange={handleChange}
            className={styles.input}
            placeholder="Search by application name"
          />
          {isSearching && (
            <Button onClick={onClearSearch} className={styles.cleanInputButton} theme="light">
              <i className="fas fa-times" />
            </Button>
          )}
        </div>

        {isNotificationEnabled
          ? (
            <Notifications
              id={NAVIGATION_NOTIFICATIONS_DOM_ID}
              parentSelector={parentSelector}
              isMobile
              withPortal
            />
          )
          : <HelpButton isMobile />}
      </header>
      <section className={classNames(styles.navSection, isMobilePage && styles.withoutFooter)}>
        {renderContent()}
      </section>
    </div>
  );
}

ActiveMenu.propTypes = {
  onChangeSearch: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  isLoadingSearch: PropTypes.bool.isRequired,
  isMobilePage: PropTypes.bool.isRequired,
  handleNavigateNext: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(menuItemShape),
  isBackItem: PropTypes.bool,
  searchValue: PropTypes.string,
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired,
};

export default withMenuItems(ActiveMenu);
