import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';

import Button from 'components/core/Button';
import Link from 'components/core/Link';
import Highlighter from 'components/core/Highlighter';

import { menuItemShape } from '../utils/shapes';
import styles from './styles.module.scss';

function MenuItem({
  item,
  searchValue,
  handleNavigateNext,
  className,
  textClassName,
  iconClassName,
  disabledProducts,
  onLinkClick,
}) {
  const { title, href, linkProperties, goTo, icon, isLocked, onClick, isLeaf = false } = item;

  let pageUrl = href;
  if (isFunction(href)) {
    pageUrl = href({ disabledProducts });
  }

  const isLink = !isEmpty(pageUrl);
  const shouldRenderIcon = !isEmpty(icon) || !(isLink || isLeaf);

  function onClickItem() {
    isFunction(onClick) ? onClick() : handleNavigateNext(goTo);
  }

  function renderIcon() {
    if (!shouldRenderIcon) {
      return null;
    }

    const iconClasses = classNames(
      icon || 'far fa-level-down-alt',
      styles.icon,
      iconClassName,
    );

    return <i className={iconClasses} />;
  }

  function renderContent() {
    const textClassNames = classNames(
      styles.textContent,
      isLocked && styles.lockedText,
      !isEmpty(searchValue) && styles.noHighlight,
      textClassName,
    );

    const content = (
      <>
        <div className={textClassNames}>
          <Highlighter
            className={styles.highlightWord}
            searchValue={searchValue}
            content={title}
          />
        </div>
        {renderIcon()}
      </>
    );

    if (isLink) {
      return (
        <Link
          {...linkProperties}
          className={styles.content}
          theme="light"
          href={pageUrl}
          onClick={onLinkClick}
        >
          {content}
        </Link>
      );
    }

    return (
      <Button
        theme="light"
        onClick={onClickItem}
        className={classNames(styles.content, isLeaf && styles.leafButton)}
      >
        {content}
      </Button>
    );
  }

  return (
    <div className={classNames(styles.container, className)}>
      {renderContent()}
    </div>
  );
}

MenuItem.defaultProps = {
  onLinkClick: () => {},
};

MenuItem.propTypes = {
  item: menuItemShape.isRequired,
  handleNavigateNext: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ),
  onLinkClick: PropTypes.func,
};

export default MenuItem;
