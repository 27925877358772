/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const ENTER_KEY = 13;

function SearchInput({ name, value, placeholder, onChange, onClickSearch }) {
  function onInputChange(event) {
    onChange(event.target.value);
  }

  function onEnterClick(event) {
    event.keyCode === ENTER_KEY && onClickSearch();
  }

  return (
    <section>
      <div className={styles.searchForm}>
        <input
          className={styles.searchInput}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onInputChange}
          onKeyUp={onEnterClick}
          autoFocus
        />
        <button type="button" onClick={onClickSearch} className={styles.searchBtn}>
          Search
        </button>
      </div>
    </section>
  );
}

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClickSearch: PropTypes.func.isRequired,
};

export default SearchInput;
