import isEmpty from 'lodash/isEmpty';

function checkLinkMatchers(pages) {
  const { pathname: pathName } = window.location;

  return pages.some(
    ({ linkMatcher }) => pathName.match(linkMatcher)
  );
}

export function checkActiveItem({ href, subItems, pages = [] }) {
  const { pathname: pathName } = window.location;

  if (!isEmpty(subItems)) {
    const isActiveItemFound = subItems.some(checkActiveItem);

    return isActiveItemFound;
  }

  const isSubPageActive = checkLinkMatchers(pages);

  const isItemActive = pathName === href;
  return isItemActive || isSubPageActive;
}

export function getItemTitle({ title, shortTitle, isDesktopLarge }) {
  if (!shortTitle) {
    return title;
  }

  return isDesktopLarge ? title : shortTitle;
}
