import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/core/Link';

import { trackHomeButtonClicked } from '../../utils/tracking';
import styles from './styles.module.scss';

function HomeButton({ className, onClick }) {
  return (
    <Link
      theme="default"
      className={classNames(styles.homeButton, className)}
      href="/"
      onClick={onClick}
    >
      <img
        className={styles.govPredictLogo}
        src="/images/intelligence-icon-dark.png"
        alt="Intelligence Logo"
      />
    </Link>
  );
}

HomeButton.defaultProps = {
  onClick: trackHomeButtonClicked,
};

HomeButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default HomeButton;
