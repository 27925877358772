import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import useMenu from 'components/Navigation/utils/useMenu';
import useSearchInput from 'components/Navigation/utils/useSearchInput';
import useSearchMenuSteps from 'components/Navigation/utils/useSearchMenuSteps';
import { findMenuItemsById, removeLockedItemsForMenu } from 'components/Navigation/MobileView/utils';
import { APP_MENU } from 'components/Navigation/utils/index';

function useMobileNavigation({
  disabledProducts,
  toggleAppMenu,
  isAppMenuOpen,
}) {
  const filteredItems = removeLockedItemsForMenu(disabledProducts);
  const { searchInput, isLoading, onChangeValue } = useSearchInput();
  const isSearching = !isEmpty(searchInput);
  const menuProps = useMenu({
    items: filteredItems,
    filterFunction: findMenuItemsById,
  });

  const {
    isInitItemStep,
    goToNextMenu,
    goToBackMenu,
    getParentTitle,
    getFoundItemsTitle,
  } = useSearchMenuSteps(searchInput, onChangeValue, filteredItems, menuProps);

  const { handleNavigateParent, handleNavigateItem } = menuProps;

  function handleNavigateNext(menuId) {
    return isSearching
      ? goToNextMenu(menuId)
      : handleNavigateItem(menuId);
  }

  function handleNavigateBack() {
    return isSearching
      ? goToBackMenu()
      : handleNavigateParent();
  }

  function handleClearSearch() {
    onChangeValue('');
  }

  function toggleNavigationMenu() {
    toggleAppMenu(APP_MENU);
  }

  useEffect(() => {
    isAppMenuOpen && handleClearSearch();
  }, [isAppMenuOpen]);

  return {
    ...menuProps,
    isSearching,
    searchValue: searchInput,
    onChangeSearch: onChangeValue,
    isLoadingSearch: isLoading,
    onClearSearch: handleClearSearch,
    handleNavigateNext,
    handleNavigateBack,
    getParentTitle,
    getFoundItemsTitle,
    isInitItemStep,
    filteredItems,
    toggleNavigationMenu,
  };
}

export default useMobileNavigation;
