import { PERMIT_MONEYBALL_CLUSTERBUCK } from 'components/Navigation/utils';

import breadcrumbs from './breadcrumbs';
import slugs from './slugs';
import applicationMenuTitles from './titles';

const { donorResearch: titles } = applicationMenuTitles;

const donorResearch = {
  id: slugs.donorResearch,
  parent: slugs.parentMenu,
  title: titles.title,
  breadcrumb: titles.title,
  columnsCount: 3,
  subItems: [
    {
      title: titles.pacRankings,
      href: '/moneyball_v2/pac-ranking/lookup',
      description: 'Compare PACs by sector, industry, and category',
      slug: slugs.moneyball,
    },
    {
      title: titles.individuals,
      href: '/moneyball_v2/individuals/lookup',
      description: 'Individual contributors from all sources',
      slug: slugs.moneyball,
      breadcrumb: breadcrumbs.individuals,
      pages: [
        {
          linkMatcher: /\/moneyball_v2\/individuals\/(?!lookup)/,
        },
      ],
    },
    {
      title: titles.corporations,
      href: '/moneyball_v2/corporations/lookup',
      description: 'Corporate contributors from all sources',
      slug: slugs.moneyball,
      breadcrumb: breadcrumbs.corporations,
      pages: [
        {
          linkMatcher: /\/moneyball_v2\/corporations\/(?!lookup)/,
        },
      ],
    },
    {
      title: titles.committees,
      href: '/moneyball_v2/committees/lookup',
      description: 'Committees registered with the FEC, IRS form 8872, and all 50 states',
      slug: slugs.moneyball,
      breadcrumb: breadcrumbs.committees,
      pages: [
        {
          linkMatcher: /\/moneyball_v2\/committees\/(?!lookup)/,
        },
      ],
    },
    {
      title: titles.candidates,
      href: '/moneyball_v2/candidates/lookup',
      description: 'Candidates according to their affiliated committees',
      slug: slugs.moneyball,
      breadcrumb: breadcrumbs.candidates,
      pages: [
        {
          linkMatcher: /\/moneyball_v2\/candidates\/(?!lookup)/,
        },
      ],
    },
    {
      title: titles.vendors,
      href: '/moneyball_v2/vendors/lookup',
      description: 'Entities receiving disbursements filed with the FEC',
      slug: slugs.moneyball,
      breadcrumb: breadcrumbs.vendors,
      pages: [
        {
          linkMatcher: /\/moneyball_v2\/vendors\/(?!lookup)/,
        },
      ],
    },
    {
      title: titles.receipts,
      href: '/moneyball_v2/receipts/lookup',
      description: 'Unique receipts sourced from the FEC, IRS form 8872, and all 50 states',
      slug: slugs.moneyball,
    },
    {
      title: titles.disbursements,
      href: '/moneyball_v2/disbursements/lookup',
      description: 'Unique disbursements sourced from the FEC',
      slug: slugs.moneyball,
    },
    {
      title: titles.smartSearch,
      href: '/moneyball_v2/smart-search',
      description: 'Build lists of contributors or recipients using complex queries',
      slug: slugs.moneyball,
    },
    {
      title: titles.clusterbuckFirstQueue,
      href: '/moneyball_v2/clusterbuck/firstqueue/lookup',
      roles: [PERMIT_MONEYBALL_CLUSTERBUCK],
    },
    {
      title: titles.clusterbuckSecondQueue,
      href: '/moneyball_v2/clusterbuck/secondqueue/lookup',
      roles: [PERMIT_MONEYBALL_CLUSTERBUCK],
    },
  ],
};

export default donorResearch;
