import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useLoader from 'utils/useLoader';
import withNavigation from 'components/Navigation/utils/withNavigation';
import QuickSearchMenu from 'components/Navigation/QuickSearch';
import MenuTransition from 'components/Navigation/common/MenuTransition';

import ApplicationMenu from './ApplicationMenu';
import NavigationBar from './ApplicationMenu/NavigationBar';
import { MenuItemsContext } from './utils/withMenuItems';
import useMobileNavigation from './utils/useMobileNavigation';
import styles from './styles.module.scss';

function MobileView({
  isSearchMenu,
  isMobilePage,
  isAppMenuOpen,
  disabledProducts,
  isFirstPageLoad,
  toggleAppMenu,
  toggleSearchMenu,
}) {
  const { isLoading, startLoading, stopLoading } = useLoader();

  const {
    isSearching,
    onChangeSearch,
    isLoadingSearch,
    onClearSearch,
    handleNavigateNext,
    handleNavigateBack,
    getParentTitle,
    getFoundItemsTitle,
    isInitItemStep,
    filteredItems,
    toggleNavigationMenu,
    ...menuProps
  } = useMobileNavigation({
    disabledProducts,
    toggleAppMenu,
    isAppMenuOpen,
  });

  const context = {
    ...menuProps,
    disabledProducts,
    isSearching,
    onChangeSearch,
    isLoadingSearch,
    onClearSearch,
    handleNavigateNext,
    handleNavigateBack,
    getParentTitle,
    getFoundItemsTitle,
    isInitItemStep,
    filteredItems,
    isMobilePage,
    toggleNavigationMenu,
    isLoading,
    startLoading,
    stopLoading,
  };

  const searchMenuClassNames = classNames(
    styles.searchMenu,
    isSearchMenu && styles.searchMenuActive,
  );

  const showNavigationBar = !isMobilePage || menuProps.isBackHome;

  return (
    <MenuItemsContext.Provider value={context}>
      {showNavigationBar && (
        <NavigationBar
          isAppMenuOpen={isAppMenuOpen}
          toggleSearchMenu={toggleSearchMenu}
          toggleNavigationMenu={toggleNavigationMenu}
          isMobilePage={isMobilePage}
          disabledProducts={disabledProducts}
        />
      )}

      <ApplicationMenu
        isAppMenuOpen={isAppMenuOpen}
        disabledProducts={disabledProducts}
        isFirstPageLoad={isFirstPageLoad}
      />

      <MenuTransition id={`search${isSearchMenu}`} isSearchMenu>
        <section id="search-menu" className={searchMenuClassNames}>
          {isSearchMenu && <QuickSearchMenu onClose={toggleSearchMenu} />}
        </section>
      </MenuTransition>
    </MenuItemsContext.Provider>
  );
}

MobileView.propTypes = {
  toggleAppMenu: PropTypes.func.isRequired,
  toggleSearchMenu: PropTypes.func.isRequired,
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  isSearchMenu: PropTypes.bool.isRequired,
  isFirstPageLoad: PropTypes.bool.isRequired,
  isAppMenuOpen: PropTypes.bool.isRequired,
  isMobilePage: PropTypes.bool.isRequired,
};

export default withNavigation(MobileView);
