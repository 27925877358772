import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import { NewNoteForNavigation } from 'components/Grm2';
import GlobalSearch from 'components/GlobalSearch';
import useModal from 'components/core/Modal/useModal';

import styles from '../../styles.module.scss';

function handleNavigateBack() {
  window.history.back();
}

function ClosedMenuBar({
  toggleNavigationMenu,
  historyLength,
  onNavigateBack,
  disabledProducts,
}) {
  const {
    isOpenModal: isGlobalSearchOpen,
    closeModal: closeGlobalSearch,
  } = useModal();

  const hasPreviousPage = historyLength > 1;

  return (
    <div className={styles.closedMenuContainer}>
      <Button
        className={styles.menu}
        onClick={toggleNavigationMenu}
        theme="light"
      >
        <img
          className={styles.iconImg}
          src="/mobile-app-icon.svg"
          alt="Menu"
        />
      </Button>

      <NewNoteForNavigation />

      {hasPreviousPage && (
        <Button onClick={onNavigateBack} className={styles.iconButton} theme="light">
          <i className="fas fa-arrow-left" />
        </Button>
      )}

      {isGlobalSearchOpen && (
        <GlobalSearch
          disabledProducts={disabledProducts}
          closeGlobalSearch={closeGlobalSearch}
          className={styles.globalQuickSearch}
        />
      )}
    </div>
  );
}

ClosedMenuBar.defaultProps = {
  historyLength: window.history.length,
  onNavigateBack: handleNavigateBack,
};

ClosedMenuBar.propTypes = {
  historyLength: PropTypes.number,
  onNavigateBack: PropTypes.func,
  toggleNavigationMenu: PropTypes.func.isRequired,
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default ClosedMenuBar;
