import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function MenuTitle({ title, className }) {
  return (
    <h1 className={classNames(styles.title, className)}>
      {title}
    </h1>
  );
}

MenuTitle.propTypes = {
  title: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default MenuTitle;
