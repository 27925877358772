import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/core/Button';
import MenuToggleButton from 'components/Navigation/MenuToggleButton';
import withMenuItems from 'components/Navigation/MobileView/utils/withMenuItems';

import styles from '../../styles.module.scss';

function OpenMenuBar({
  showReturnArrow,
  isBackHome,
  handleNavigateBack,
  handleNavigateHome,
  toggleNavigationMenu,
  isSearching,
  isMobilePage,
  isLoading,
}) {
  const displayBackButton = showReturnArrow || isBackHome;
  const displayHomeButton = !isSearching && isBackHome;
  const commonProps = { theme: 'light', className: styles.iconButton };

  return (
    <>
      {isLoading && <div className={styles.loadingOverlay} />}
      <div className={styles.openMenuContainer}>
        <div className={styles.baseItem}>
          {displayBackButton && (
            <Button
              {...commonProps}
              data-spec="nav-back-button"
              onClick={handleNavigateBack}
            >
              <i className="fas fa-arrow-left" />
            </Button>
          )}
        </div>
        <div className={styles.baseItem}>
          {displayHomeButton && (
            <Button
              {...commonProps}
              data-spec="nav-home-button"
              onClick={handleNavigateHome}
            >
              <i className="fas fa-home" />
            </Button>
          )}
        </div>
        <MenuToggleButton
          isActive={!isMobilePage}
          onClick={toggleNavigationMenu}
          disabled={isLoading}
        />
      </div>
    </>
  );
}

OpenMenuBar.propTypes = {
  toggleNavigationMenu: PropTypes.func.isRequired,
  isBackHome: PropTypes.bool.isRequired,
  showReturnArrow: PropTypes.bool.isRequired,
  handleNavigateBack: PropTypes.func.isRequired,
  handleNavigateHome: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  isMobilePage: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withMenuItems(OpenMenuBar);
