import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from 'utils/breakpoints/useMediaQuery';

import { NavigationContext } from './utils/navContext';
import useNavigation from './useNavigation';
import GlobalHeader from './GlobalHeader';
import MobileView from './MobileView';

import styles from './styles.module.scss';

function Navigation({ disabledProducts, userImgPath, userName }) {
  const { isMobile, isDesktopLarge } = useMediaQuery();

  const {
    appMenuTab,
    isSearchMenu,
    setIsSearchMenu,
    isFirstPageLoad,
    toggleAppMenu,
    toggleSearchMenu,
    isAppMenuOpen,
    isMobilePage,
  } = useNavigation();

  const navContext = {
    disabledProducts,
    appMenuTab,
    isSearchMenu,
    setIsSearchMenu,
    isFirstPageLoad,
    toggleAppMenu,
    toggleSearchMenu,
    isAppMenuOpen,
    isMobilePage,
    userImgPath,
    userName,
    isDesktopLarge,
  };

  const isFederalRegulations = window.location.href.includes('/federal/regulations/');

  if (isFederalRegulations) {
    const elements = document.getElementsByClassName('arrow-back-for-federal-regulations');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  return (
    <div className={styles.containerNavigation}>
      <NavigationContext.Provider value={navContext}>
        {isMobile ? <MobileView /> : <GlobalHeader />}
      </NavigationContext.Provider>
    </div>
  );
}

Navigation.propTypes = {
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  userImgPath: PropTypes.string,
  userName: PropTypes.string,
};

export default Navigation;
