import fetcher from 'utils/fetcher';
import { DEFAULT_DEBOUNCE_TIME } from 'utils/constants/intervals';

export function filterGlobalFunction(inputValue, setIsLoading, onFilterSuccess) {
  function onSuccess(data) {
    onFilterSuccess(data);
    setIsLoading(false);
  }

  if (inputValue) {
    fetcher({
      asyncDelay: DEFAULT_DEBOUNCE_TIME,
      inputText: inputValue,
      onSuccess,
      strategySlug: 'general_autocomplete',
      reverseSearch: false,
    });
  } else {
    /** Reset search values when empty input value */
    onSuccess([]);
    setIsLoading(false);
  }
}
