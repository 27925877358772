import { isEmpty, pickBy, identity } from 'lodash';

import applicationMenuItems, { mobileParentMenuItem } from './applicationMenuItems';

const mobileNavigation = [mobileParentMenuItem];

const handleNestedItems = (subItem) => {
  const { subItems: nestedItems } = subItem;

  if (!isEmpty(nestedItems)) {
    // eslint-disable-next-line no-use-before-define
    mobileNavigation.push(transformNavItemToMobile(subItem));
  }
};

// TODO: Redesign this transformer if we find a better solution
const transformNavItemToMobile = ({
  id,
  goTo,
  title,
  parent,
  href,
  slug,
  breadcrumb,
  pages,
  betaFlag,
  roles,
  subItems = [],
}) => {
  subItems.map(handleNestedItems);
  return pickBy(
    {
      id,
      goTo,
      title,
      parent,
      href,
      slug,
      breadcrumb,
      pages,
      betaFlag,
      roles,
      items: subItems.map(transformNavItemToMobile),
    },
    identity,
  );
};

mobileNavigation.push(...applicationMenuItems.map(transformNavItemToMobile));

export default mobileNavigation;
