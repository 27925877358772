import isEmpty from 'lodash/isEmpty';

import { filterItemByUserRole, filterItemByUserDevice } from 'components/Navigation/utils/index';
import userMenuData from 'components/Navigation/menuData/mobileUserMenuItems';

export function findUserMenuEnabledItems(isMobile = false) {
  const menuItems = filterItemByUserRole(userMenuData);

  /** Remove nested items by user role */
  menuItems.forEach((nestedItem) => {
    nestedItem.items = filterItemByUserRole(nestedItem.items);
  });

  menuItems.forEach((menuItem) => {
    menuItem.items = filterItemByUserDevice(menuItem, isMobile);
  });

  return menuItems;
}

export function getUserMenuById(menuId, enabledItems) {
  if (isEmpty(menuId)) {
    return enabledItems;
  }

  return enabledItems.filter((item) => item.id === menuId);
}
