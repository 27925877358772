import compact from 'lodash/compact';

import { GOVPREDICT_ADMIN, COMPANY_ADMIN } from 'components/Navigation/utils';
import {
  GENERAL_HELP_URL,
} from 'components/core/HelpButton/components/TooltipContent/constants';
import { trackHelpCenterPageVisited } from 'utils/tracking';
import styles from 'components/Navigation/GlobalHeader/components/UserMenuButton/styles.module.scss';

import titles from './titles';
import paths from './paths';

const handleClick = visitedPage => () => {
  trackHelpCenterPageVisited(visitedPage);
};

// TODO: adjust once SSO is out of beta
const {
  isNotificationEnabled,
  isSSOEnabled,
} = gon || {};

const userMenuItems = [
  compact([
    {
      title: titles.accountSettings.title,
      keepFontSize: true,
      subItems: [
        {
          title: titles.accountSettings.manageProfile,
          href: paths.accountSettings.manageProfile,
        },
        {
          title: titles.accountSettings.changePassword,
          href: paths.accountSettings.changePassword,
        },
        {
          title: titles.accountSettings.manageProfilePhoto,
          href: paths.accountSettings.manageProfilePhoto,
        },
        {
          title: titles.accountSettings.admin,
          href: paths.accountSettings.admin,
          roles: [GOVPREDICT_ADMIN],
          linkProps: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        },
        {
          title: titles.accountSettings.logOut,
          href: paths.accountSettings.logOut,
          linkProps: {
            'data-method': 'delete',
            rel: 'nofollow',
          },
        },
      ],
    },
    (isNotificationEnabled && {
      title: titles.help.title,
      keepFontSize: true,
      subItems: [
        {
          title: titles.help.generalHelp,
          href: GENERAL_HELP_URL,
          linkProps: {
            isExternal: true,
            displayExternalIcon: true,
            iconClassName: styles.externalLinkIcon,
            onClick: handleClick('general help'),
          },
        },
      ],
    }),
  ]),
  [
    {
      title: titles.organizationSettings.title,
      keepFontSize: true,
      subItems: [
        {
          title: titles.organizationSettings.manageOrganization,
          href: paths.organizationSettings.manageOrganization,
        },
        {
          title: titles.organizationSettings.users,
          href: paths.organizationSettings.users,
        },
        ...isSSOEnabled ? [{
          title: titles.organizationSettings.sso,
          href: paths.organizationSettings.sso,
          roles: [COMPANY_ADMIN],
        }] : [],
        {
          title: titles.organizationSettings.inviteUsers,
          href: paths.organizationSettings.inviteUsers,
        },
        {
          title: titles.organizationSettings.institutionalData,
          href: paths.organizationSettings.institutionalData,
        },
      ],
    },
  ],
];

export default userMenuItems;
