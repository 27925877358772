const paths = {
  accountSettings: {
    manageProfile: '/profile/users/edit',
    changePassword: '/profile/passwords/edit',
    manageProfilePhoto: '/profile/photos/edit',
    admin: '/admin',
    logOut: '/logout',
  },

  organizationSettings: {
    manageOrganization: '/manage/companies',
    users: '/manage/users',
    sso: '/manage/sso',
    inviteUsers: '/manage/invite_codes/new',
    institutionalData: '/manage/institutional_data',
  },
};

export default paths;
