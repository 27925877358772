import React, { useState } from 'react';
import PropTypes from 'prop-types';

import withNavigation from 'components/Navigation/utils/withNavigation';

import HomeButton from './components/HomeButton';
import NavigationBar from './components/NavigationBar';
import UserMenuButton from './components/UserMenuButton';
import styles from './styles.module.scss';

function GlobalHeader({ isDesktopLarge }) {
  const [isSearchDisplayed] = useState(false);

  const shouldDisplayDesktopNavBar = isDesktopLarge && !isSearchDisplayed;

  return (
    <section className={styles.globalHeaderWrapper}>
      <div className={styles.globalHeader}>
        <div className={styles.leftContent}>
          <HomeButton className={styles.homeButton} />

          {shouldDisplayDesktopNavBar && <NavigationBar />}
        </div>

        <div className={styles.rightContent}>

          <UserMenuButton />
        </div>
      </div>
      {!isDesktopLarge && <NavigationBar />}
    </section>
  );
}

GlobalHeader.propTypes = {
  isDesktopLarge: PropTypes.bool.isRequired,
};

export default withNavigation(GlobalHeader);
