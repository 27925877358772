import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';

import styles from './styles.module.scss';

function Title({ title, isBackHome, setIsBackHome, onClickTitle }) {
  const onClickBackHome = () => {
    onClickTitle('parent');
    setIsBackHome(false);
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      { isBackHome && (
        <Button
          theme="light"
          className={styles.homeButton}
          onClick={onClickBackHome}
        >
          <i className={classNames('fas', 'fa-home-lg-alt')} />
        </Button>
      ) }
    </div>
  );
}

Title.propTypes = {
  title: PropTypes.string,
  isBackHome: PropTypes.bool,
  setIsBackHome: PropTypes.func,
  onClickTitle: PropTypes.func,
};

export default Title;
