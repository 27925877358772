import compact from 'lodash/compact';

import { GOVPREDICT_ADMIN, COMPANY_ADMIN } from 'components/Navigation/utils';
import {
  GENERAL_HELP_URL,
} from 'components/core/HelpButton/components/TooltipContent/constants';
import { trackHelpCenterPageVisited } from 'utils/tracking';
import { openFreshChat } from 'utils/freshchat';

import titles from './titles';
import paths from './paths';
import slugs from './slugs';

const handleClick = visitedPage => () => {
  trackHelpCenterPageVisited(visitedPage);
};

// TODO: adjust once SSO is out of beta
const {
  isNotificationEnabled,
  isSSOEnabled,
} = gon || {};

const mobileUserMenuItems = compact([
  {
    id: 'parent',
    items: [
      {
        title: titles.myProfile.title,
        goTo: slugs.myProfile,
      },
      {
        title: titles.organizationSettings.title,
        roles: [COMPANY_ADMIN],
        goTo: slugs.organizationSettings,
      },
      {
        title: 'Admin',
        roles: [GOVPREDICT_ADMIN],
        href: paths.accountSettings.admin,
        icon: 'fas fa-external-link',
        linkProperties: {
          target: '_blank',
        },
      },
      ...isNotificationEnabled ? [{
        title: titles.help.title,
        goTo: slugs.help,
      }] : [],
      {
        title: 'Log out',
        href: paths.accountSettings.logOut,
        linkProperties: {
          'data-method': 'delete',
          rel: 'nofollow',
        },
      },
    ],
  },
  {
    id: slugs.myProfile,
    title: titles.myProfile.title,
    parent: 'parent',
    breadcrumb: titles.myProfile.title,
    items: [
      {
        title: titles.myProfile.manageProfile,
        href: paths.accountSettings.manageProfile,
      },
      {
        title: titles.myProfile.changePassword,
        href: paths.accountSettings.changePassword,
      },
      {
        title: titles.myProfile.manageProfilePhoto,
        href: paths.accountSettings.manageProfilePhoto,
      },
    ],
  },
  {
    id: slugs.organizationSettings,
    roles: [COMPANY_ADMIN],
    title: titles.organizationSettings.title,
    parent: 'parent',
    breadcrumb: titles.organizationSettings.title,
    items: [
      {
        title: titles.organizationSettings.manageOrganization,
        href: paths.organizationSettings.manageOrganization,
      },
      {
        title: titles.organizationSettings.users,
        href: paths.organizationSettings.users,
      },
      isSSOEnabled && {
        title: titles.organizationSettings.sso,
        href: paths.organizationSettings.sso,
        roles: [COMPANY_ADMIN],
      },
      {
        title: titles.organizationSettings.inviteUsers,
        href: paths.organizationSettings.inviteUsers,
      },
      {
        title: titles.organizationSettings.institutionalData,
        href: paths.organizationSettings.institutionalData,
      },
    ],
  },
  (isNotificationEnabled && {
    /*
      Investigate product specific help option
      front/components/core/HelpButton/components/TooltipContent/index.jsx:29
    */
    id: slugs.help,
    title: titles.help.title,
    parent: 'parent',
    breadcrumb: titles.help.title,
    items: [
      {
        title: titles.help.generalHelp,
        href: GENERAL_HELP_URL,
        linkProperties: {
          isExternal: true,
          onClick: handleClick('general help'),
        },
      },
      {
        title: titles.help.liveSupport,
        onClick: openFreshChat,
        isLeaf: true,
      },
    ],
  }),
]);

export default mobileUserMenuItems;
