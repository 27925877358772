import { trackNavigationBarEvent } from 'utils/tracking/navigation';

export function trackTopLevelItemClicked({ itemTitle }) {
  const { user_id: userId } = gon.currentUser || {};
  trackNavigationBarEvent('clicked on top level item', { userId, itemTitle });
}

export function trackHomeButtonClicked() {
  const { user_id: userId } = gon.currentUser || {};
  trackNavigationBarEvent('clicked on GP Logo', { userId });
}

export function trackUserMenuOpened() {
  const { user_id: userId } = gon.currentUser || {};
  trackNavigationBarEvent('clicked on avatar', { userId });
}

export function trackNewNoteClicked() {
  const { user_id: userId } = gon.currentUser || {};
  trackNavigationBarEvent('clicked on new note button', { userId });
}

export function trackNewTaskClicked() {
  const { user_id: userId } = gon.currentUser || {};
  trackNavigationBarEvent('clicked on new task button', { userId });
}
