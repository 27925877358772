import slugs from 'components/Navigation/menuData/slugs';

/** Also modify in sidebar_placeholder.haml when change pinnedNavId */
const NAV_STATE_ID = 'navMenuState';

export function getNavStateItem() {
  return JSON.parse(sessionStorage.getItem(NAV_STATE_ID));
}

export function setNavStateItem(menuId, navId) {
  const navState = {
    menuId: menuId || slugs.parentMenu,
    navId,
  };
  sessionStorage.setItem(NAV_STATE_ID, JSON.stringify(navState));
}

export function deleteNavStateItem() {
  sessionStorage.removeItem(NAV_STATE_ID);
}

export function resetNavStateToParent(menuId) {
  setNavStateItem(slugs.parentMenu, menuId);
}
