import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  getNavStateItem,
  setNavStateItem,
} from 'components/Navigation/utils/navStateItems';
import { APP_MENU } from 'components/Navigation/utils';
import slugs from 'components/Navigation/menuData/slugs';
import { closeFreshChat } from 'utils/freshchat';

function useMenu({
  items,
  filterFunction,
  isInit = true,
  navId = APP_MENU,
}) {
  const navStateItem = getNavStateItem();

  const [isBackItem, setIsBackItem] = useState(false);
  const [showReturnArrow, setShowReturnArrow] = useState(false);
  const [isBackHome, setIsBackHome] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [activeMenuId, setActiveMenuId] = useState(null);

  useEffect(() => {
    if (isInit) {
      if (navStateItem && (navStateItem.menuId === slugs.parentMenu)) {
        setIsBackHome(false);
      }

      /** Check if has saved state menu - open it */
      if (navStateItem && (navStateItem.navId === navId)) {
        setIsBackHome(true);
        setActiveMenuId(navStateItem.menuId);
      } else {
        setIsBackHome(false);
        setMenuItems(filterFunction(activeMenuId, items));
      }
    }
  }, []);

  useEffect(() => {
    if (activeMenuId) {
      setMenuItems(filterFunction(activeMenuId, items));
      setIsBackHome(true);
    }

    /** Track steps when changing menu */
    setNavStateItem(activeMenuId, navId);

    /** Remove pinned item when menu is home */
    if (activeMenuId === slugs.parentMenu) {
      setIsBackHome(false);
    }
  }, [activeMenuId]);

  function handleNavigateParent() {
    if (!isEmpty(menuItems)) {
      const { parent } = menuItems[0];

      setActiveMenuId(parent);
      setIsBackItem(true);
      closeFreshChat();
    }
  }

  function handleNavigateHome() {
    setActiveMenuId(slugs.parentMenu);
    setIsBackItem(true);
    closeFreshChat();
  }

  function handleNavigateItem(menuId) {
    setActiveMenuId(menuId);
    setIsBackItem(false);
    closeFreshChat();
  }

  return {
    isBackHome,
    setIsBackHome,
    menuItems,
    setMenuItems,
    activeMenuId,
    setActiveMenuId,
    isBackItem,
    setIsBackItem,
    showReturnArrow,
    setShowReturnArrow,
    handleNavigateParent,
    handleNavigateHome,
    handleNavigateItem,
  };
}

export default useMenu;
