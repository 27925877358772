import React from 'react';

export const MenuItemsContext = React.createContext();

function withMenuItems(Component) {
  return function MenuItemsDataComponent(props) {
    return (
      <MenuItemsContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </MenuItemsContext.Consumer>
    );
  };
}

export default withMenuItems;
