import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import { closeFreshChat } from 'utils/freshchat';

import styles from './styles.module.scss';

function MenuToggleButton({
  activeClassName,
  inactiveClassName,
  isActive,
  inactiveContent,
  onClick,
  isRound,
  isDisabled,
  ...restProps
}) {
  const buttonClassNames = classNames(
    styles.baseButton,
    isRound && styles.round,
    isDisabled && styles.isDisabled,
    isActive
      ? [styles.activeButton, activeClassName]
      : inactiveClassName,
  );
  const handleClick = () => {
    closeFreshChat();
    onClick();
  };

  return (
    <Button
      {...restProps}
      theme="light"
      onClick={handleClick}
      className={buttonClassNames}
      disabled={isDisabled}
    >
      {isActive
        ? <img src="/images/close-blue-icon.svg" alt="Close button" />
        : inactiveContent}
    </Button>
  );
}

MenuToggleButton.defaultProps = {
  isRound: true,
  isDisabled: false,
};

MenuToggleButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  inactiveContent: PropTypes.node,
  onClick: PropTypes.func,
  activeClassName: PropTypes.string,
  inactiveClassName: PropTypes.string,
  isRound: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default MenuToggleButton;
