import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, isFunction, includes } from 'lodash';

import Link from 'components/core/Link';
import withNavigation from 'components/Navigation/utils/withNavigation';
import { authorizeItemByRoleAndBetaFlag } from 'components/Navigation/utils';
import { isExternal } from 'utils/url';

import { menuItemShape } from '../../utils/shapes';
import styles from './styles.module.scss';

function MenuItem({
  slug,
  title,
  href,
  linkProps,
  subItems,
  description,
  disabledProducts,
  roles,
  withDivider,
  columnsCount,
  withNestedLevel,
  keepFontSize,
  betaFlag,
  parentClassName,
  leafClassName,
}) {
  if (!authorizeItemByRoleAndBetaFlag({ roles, betaFlag })) {
    return null;
  }

  const isDisabled = includes(disabledProducts, slug);

  const renderLeafNode = () => {
    const leafNodeClassNames = classNames(
      styles.leafNode,
      isDisabled && styles.disabled,
      keepFontSize && styles.keepFontSize,
      leafClassName,
    );

    let pageURL = href;

    if (isFunction(href)) {
      pageURL = href({ disabledProducts });
    }

    const isExternalUrl = isExternal(pageURL);
    const url = isDisabled && isExternalUrl ? null : pageURL;

    return (
      <Link
        {...linkProps}
        theme="light"
        size="none"
        href={url}
        disabled={isDisabled}
        className={leafNodeClassNames}
      >
        <b className={styles.title}>
          {isDisabled && <i className="fas fa-lock" />}
          {title}
        </b>

        {description && (
          <span className={styles.description}>
            {description}
          </span>
        )}
      </Link>
    );
  };

  const renderSubItems = (subItem, columnIndex) => (
    <MenuItem
      {...subItem}
      withNestedLevel={withNestedLevel}
      disabledProducts={disabledProducts}
      keepFontSize={keepFontSize}
      key={columnIndex}
      betaFlag={betaFlag}
      leafClassName={leafClassName}
    />
  );

  const subItemsGridClassNames = classNames(
    styles.subItemsGrid,
    withDivider && styles.withDivider,
  );

  const parentClassNames = classNames(
    styles.parentNode,
    (columnsCount > 1 && withNestedLevel) && styles.withNestedLevel,
    parentClassName,
  );

  const subItemsGridStyles = {
    columns: columnsCount,
  };

  const renderParentNode = () => (
    <div className={parentClassNames}>
      <b className={styles.title}>
        {title}
      </b>

      <div
        className={subItemsGridClassNames}
        style={subItemsGridStyles}
      >
        {subItems.map(renderSubItems)}
      </div>
    </div>
  );

  if (isEmpty(subItems)) {
    return renderLeafNode();
  }

  return renderParentNode();
}

MenuItem.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  linkProps: PropTypes.shape({
    target: PropTypes.string,
    rel: PropTypes.string,
  }),
  subItems: PropTypes.arrayOf(menuItemShape),
  description: PropTypes.string,
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ),
  roles: PropTypes.arrayOf(PropTypes.string),
  withDivider: PropTypes.bool,
  withNestedLevel: PropTypes.bool,
  keepFontSize: PropTypes.bool,
  parentClassName: PropTypes.string,
  leafClassName: PropTypes.string,
};

export default withNavigation(MenuItem);
