import React from 'react';
import PropTypes from 'prop-types';

import FooterBar from 'components/Navigation/MobileView/common/FooterBar';

import OpenMenuBar from './components/OpenMenuBar';
import ClosedMenuBar from './components/ClosedMenuBar';

function NavigationBar({ isAppMenuOpen, disabledProducts, ...restProps }) {
  const renderFooterContent = () => {
    if (isAppMenuOpen) {
      return <OpenMenuBar {...restProps} />;
    }

    return (
      <ClosedMenuBar
        disabledProducts={disabledProducts}
        {...restProps}
      />
    );
  };

  return (
    <FooterBar>
      {renderFooterContent()}
    </FooterBar>
  );
}

NavigationBar.propTypes = {
  disabledProducts: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  isAppMenuOpen: PropTypes.bool.isRequired,
};

export default NavigationBar;
