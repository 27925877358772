import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/core/Loader';
import Title from 'components/Navigation/common/Title';
import List from 'components/Navigation/QuickSearch/List';
import SearchInput from 'components/Navigation/QuickSearch/SearchInput';
import useQuickSearch from 'components/Navigation/QuickSearch/useQuickSearch';

import styles from './styles.module.scss';

function QuickSearchMenu({ onClose }) {
  const {
    items,
    searchValue,
    isLoading,
    isSearching,
    setIsSearching,
    onChangeValue,
    onClickSearch,
    onClearValue,
  } = useQuickSearch();

  const FoundNumberItems = !isLoading && isSearching && (
    <h3 className={styles.foundTitle}>
      {items.length === 0
        ? 'No results found'
        : `Displaying ${items.length} results for '${searchValue}'`}

      <button type="button" className={styles.link} onClick={onClearValue}>
        Clear results
      </button>
    </h3>
  );

  const FoundResults = isLoading
    ? <Loader customStyle={styles.loadingSearch} />
    : (
      <List
        items={items}
        highlightValue={searchValue}
        isSearching={isSearching}
        setIsSearching={setIsSearching}
      />
    );

  return (
    <Fragment>
      <div className={styles.topMenu}>
        <div className={styles.searchTitleLayout}>
          <button type="button" className={styles.backBtn} onClick={onClose}>
            <i className="fal fa-arrow-left" />
          </button>
          <Title title="Federal Quick Search" />
        </div>

        <SearchInput
          name="global-search"
          placeholder="Search for federal bills, committees, and legislators"
          value={searchValue}
          onChange={onChangeValue}
          onClickSearch={onClickSearch}
        />
        {FoundNumberItems}
      </div>

      <div className={styles.middleMenu}>{FoundResults}</div>
    </Fragment>
  );
}

QuickSearchMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default QuickSearchMenu;
