import slugs from './slugs';
import titles from './titles';

const mobileParentMenuItem = {
  id: slugs.parentMenu,
  items: [
    {
      title: titles.donorResearch.title,
      slug: slugs.donorResearch,
      goTo: slugs.donorResearch,
    },
  ],
};

export default mobileParentMenuItem;
