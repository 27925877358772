import React from 'react';

import { NavigationContext } from './navContext';

function withNavigation(Component) {
  return function NavComponent(props) {
    return (
      <NavigationContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </NavigationContext.Consumer>
    );
  };
}

export default withNavigation;
